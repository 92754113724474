<template>
  <div class="showdown" v-html="dataHTML" />
</template>
<script lang="ts" setup>
import { computed, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    data?: string
    settings?: Record<string, boolean>
  }>(),
  {
    settings: {
      openLinksInNewWindow: true
    }
  }
)

const converter = new showdown.Converter(props.settings)

const dataHTML = computed(() => converter.makeHtml(props.data))
</script>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({ name: 'Showdown' })
</script>

<style>
.showdown li {
  padding: 2px 0px 2px 5px;
}
.showdown ol {
  list-style-type: decimal;
  padding: 5px 0px 5px 20px;
}
.showdown ul {
  list-style-type: circle;
  padding: 5px 0px 5px 20px;
}
</style>
